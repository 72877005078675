import React from 'react';
import styles from './GameProductsReel.module.scss';
import GameBoxArt from '../GameBoxArt/GameBoxArt';
import OptimizedImage from '../OptimizedImage/OptimizedImage';
import Link from 'next/link';

type GameProduct = {
  name: string;
  url: string;
  logo: {
    url: string;
    aspectRatio: number;
  };
};

export type GameProductsReelGameViewModel = {
  name: string;
  url: string;
  boxArtImageUrl: string;
  products: Array<GameProduct>;
};

export type GameProductsReelViewModel = {
  games: Array<GameProductsReelGameViewModel>;
  onBoxArtMouseEnter?: (game: GameProductsReelGameViewModel) => void;
};

export default function GameProductsReel({
  games,
  onBoxArtMouseEnter,
}: GameProductsReelViewModel): JSX.Element {
  return (
    <div className={styles.container}>
      {games.map((game) => {
        return (
          <div key={game.name} className={styles.gameContainer}>
            <GameBoxArt
              name={game.name}
              url={game.url}
              src={game.boxArtImageUrl}
              onMouseEnter={() => onBoxArtMouseEnter?.(game)}
            />
            <div className={styles.productsContainer}>
              {game.products.map((product) => {
                return (
                  <div key={product.name} className={styles.product}>
                    <Link href={product.url}>
                      <OptimizedImage
                        src={product.logo.url}
                        alt={product.name}
                        height={26}
                        width={26 * product.logo.aspectRatio}
                        isZoomable={false}
                        includeMargin={false}
                      />
                      {/* In the case where there is only one product and the image isn't horizontally long, show up to the first 2 words of its name */}
                      {game.products.length === 1 &&
                        product.logo.aspectRatio === 1 && (
                          <div>
                            <span>{product.name.split(' ')[0]}</span>
                            {product.name.split(' ').length > 1 && (
                              <span>{product.name.split(' ')[1]}</span>
                            )}
                          </div>
                        )}
                    </Link>
                  </div>
                );
              })}
            </div>
            <div className={styles.gameName}>
              <Link href={game.url}>{game.name}</Link>
            </div>
          </div>
        );
      })}
    </div>
  );
}
