import React from 'react';
import SeoViewModel from '../../../resources/js/typings/viewModels/seoViewModel';
import Head from 'next/head';
import renderEnhancedMarkdownToString from '../../../resources/js/components/EnhancedMarkdown/helpers/renderEnhancedMarkdownToString';
import { GameViewModel } from '../../../resources/js/typings/viewModels/gameViewModel';

export default function Seo({
  title,
  description,
  imageUrl,
  isLargeImage,
  type,
  siteName,
  preventIndexing,
  game,
}: SeoViewModel & { game?: GameViewModel }): JSX.Element {
  const renderedTitle = renderEnhancedMarkdownToString(decodeTags(title), game);
  const renderedDescription = renderEnhancedMarkdownToString(
    decodeTags(description),
    game
  );

  return (
    <Head>
      <title>{`${renderedTitle} | ${siteName}`}</title>
      <meta property='og:title' key='og:title' content={renderedTitle} />
      {type && <meta property='og:type' key='og:type' content={type} />}
      {siteName && (
        <meta property={'og:site_name'} key='og:site_name' content={siteName} />
      )}
      <meta
        name='description'
        key='description'
        content={renderedDescription}
      />
      <meta
        property='og:description'
        key='og:description'
        content={renderedDescription}
      />
      {preventIndexing && (
        <>
          <meta name='robots' content='noindex' />
          <meta name='googlebot' content='noindex' />
        </>
      )}
      {imageUrl && (
        <>
          <meta key='og:image' property='og:image' content={imageUrl} />
          <meta property='twitter:image' content={imageUrl} />
          {isLargeImage && (
            <>
              <meta name='twitter:card' content='summary_large_image' />
              <meta name='twitter:title' content={renderedTitle} />
            </>
          )}
        </>
      )}
    </Head>
  );
}

function decodeTags(input: string): string {
  return input.split('&lt;').join('<').split('&gt;').join('>');
}
