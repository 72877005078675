import React, { Fragment, ReactNode } from 'react';
import styles from './GameProductsTimeline.module.scss';
import OptimizedImage from '../OptimizedImage/OptimizedImage';
import Body from '../../../resources/js/components/Body/Body';
import Heading from '../../../resources/js/components/Heading/Heading';

export type GameProductsTimelineViewModel = {
  supportedGamesLabel: string;
  products: Array<{
    sinceLabel: string;
    title: string;
    description: string;
    url?: string;
    imageUrl: string;
    games: Array<{
      title: string;
      url: string | null;
      imageUrl: string;
      isAvailable: boolean;
    }>;
  }>;
};

export default function GameProductsTimeline({
  supportedGamesLabel,
  products,
}: GameProductsTimelineViewModel): JSX.Element {
  return (
    <div className={styles.timeline}>
      {products.map((product, index) => (
        <Product
          key={product.title}
          {...product}
          index={index}
          supportedGamesLabel={supportedGamesLabel}
        />
      ))}
    </div>
  );
}

function Product({
  index,
  supportedGamesLabel,
  sinceLabel,
  title,
  description,
  url,
  imageUrl,
  games,
}: GameProductsTimelineViewModel['products'][0] &
  Pick<GameProductsTimelineViewModel, 'supportedGamesLabel'> & {
    index: number;
  }) {
  const Wrapper = ({ children }: { children: ReactNode }) =>
    url ? (
      <a href={url} target={'_blank'} rel='noreferrer'>
        {children}
      </a>
    ) : (
      <Fragment>{children}</Fragment>
    );

  return (
    <Wrapper>
      <div
        className={
          styles.product +
          ' ' +
          (index % 2 === 0 ? styles.productOdd : styles.productEven)
        }
      >
        <Body>
          <div className={styles.titleAndSince}>
            <Heading level={3} styleLevel={2}>
              {title}
            </Heading>
            <span className={styles.since}>{sinceLabel}</span>
          </div>
          <p>{description}</p>
          <div className={styles.supportedGames}>
            <span className={styles.supportedGameLabel}>
              {supportedGamesLabel}:
            </span>
            <div className={styles.supportedGameIcons}>
              {games.map((game, index) => (
                <GameIcon key={index} {...game} url={url ? null : game.url} />
              ))}
            </div>
          </div>
        </Body>
        <div className={styles.productImage}>
          <OptimizedImage
            src={imageUrl}
            alt={title}
            width={242}
            height={211}
            isZoomable={false}
            includeMargin={false}
          />
        </div>
      </div>
    </Wrapper>
  );
}

function GameIcon({
  title,
  url,
  imageUrl,
  isAvailable,
}: GameProductsTimelineViewModel['products'][0]['games'][0]) {
  const Wrapper = ({ children }: { children: ReactNode }) =>
    isAvailable ? (
      url ? (
        <a href={url} target={'_blank'} rel='noreferrer'>
          {children}
        </a>
      ) : (
        <Fragment>{children}</Fragment>
      )
    ) : (
      <div className={styles.productImageNotAvailable}>{children}</div>
    );

  return (
    <Wrapper>
      <OptimizedImage
        src={imageUrl}
        alt={title}
        width={32}
        height={32}
        isZoomable={false}
        includeMargin={false}
      />
    </Wrapper>
  );
}
