import React from 'react';
import { StaffAvatarViewModel } from '../StaffAvatar/StaffAvatar';
import styles from './StaffBox.module.scss';
import cx from 'classnames';

export type StaffBoxProps = Omit<StaffAvatarViewModel, 'imageUrl'>;

export default function StaffBox({
  name,
  title,
  style,
}: StaffBoxProps): JSX.Element {
  return (
    <div
      className={cx(styles.container, {
        [styles.orange]: style === 'orange',
        [styles.purple]: style === 'purple',
        [styles.red]: style === 'red',
      })}
    >
      <div className={styles.content}>
        <span className={styles.name}>{name}</span>
        <span className={styles.title}>{title}</span>
      </div>
    </div>
  );
}
