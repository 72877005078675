import { GetStaticProps } from 'next';
import configureSentryScope from '../helpers/configureSentryScope';
import { getPlaiceholder } from 'plaiceholder';
import convertImageUrlToS3ImageUrl from '../helpers/convertImageUrlToS3ImageUrl';
import ArchonIndexPage, {
  ArchonIndexPageProps,
} from '../components/ArchonIndexPage/ArchonIndexPage';
import React from 'react';
import getIndexPageViewModels from '../helpers/getIndexPageViewModels';
import ArchonIndexPageViewModel from '../../resources/js/typings/viewModels/archonIndexPageViewModel';
import OptimizedImage, {
  OptimizedImageViewModelProps,
} from '../components/OptimizedImage/OptimizedImage';
import { ViewModels } from '../contexts/useViewModels';
import { HasViewModelBuilder } from '../typings/viewModelBuilder';

export const IndexPagePath = '';

export default function IndexPage(props: ArchonIndexPageProps): JSX.Element {
  return <ArchonIndexPage {...props} />;
}

export const getStaticProps: GetStaticProps = async ({ params }) => {
  configureSentryScope(params);

  const translationKeys = [
    'archon.level_up_your_gameplay',
    'archon.level_up_your_gameplay_desc',
    'archon.what_weve_built_so_far',
    'archon.what_weve_built_so_far_desc',
    'archon.meet_the_team',
    'archon.meet_the_team_desc',
    'archon.passionate_team',
    'archon.passionate_team_desc',
    'archon.product_focused',
    'archon.product_focused_desc',
    'archon.fully_remote',
    'archon.fully_remote_desc',
    'archon.learn_more_about_the_archon_team',
  ];
  const pageViewModels = await getIndexPageViewModels(translationKeys);
  const componentViewModels = await getOptimizedImageViewModels(
    pageViewModels.indexPage
  );

  return {
    props: {
      googleAnalytics: pageViewModels.googleAnalytics,
      translations: pageViewModels.translations,
      header: pageViewModels.header,
      footer: pageViewModels.footer,
      archonIndexPage: pageViewModels.indexPage,
      viewModels: componentViewModels,
    },
    revalidate: 180,
  };
};

async function getOptimizedImageViewModels(
  page: ArchonIndexPageViewModel
): Promise<ViewModels> {
  const viewModels: ViewModels = {};
  const { buildKey, buildModel } = (
    OptimizedImage as unknown as HasViewModelBuilder<OptimizedImageViewModelProps>
  ).viewModelBuilder;

  const buildViewModel = async (props: {
    src: string;
    width: number;
    height: number;
  }) => {
    viewModels[buildKey(props)] = await buildModel(props, {
      getPlaiceholder: async (src: string) =>
        await getPlaiceholder(convertImageUrlToS3ImageUrl(src)),
      graphQLViewModels: {},
      graphQLViewModelQueries: [],
    });
  };

  const allProps = [
    ...page.gameProductsReel.games.map((game) => ({
      src: game.boxArtImageUrl,
      width: 148,
      height: 198,
    })),
    ...page.gameProductsReel.games
      .map((game) => game.products)
      .reduce((x, y) => x.concat(y))
      .map((product) => product.logo)
      // NextJS only recommends pre-blurring images that are larger than 40x40
      .filter((logo) => 26 * logo.aspectRatio * 26 > 40 * 40)
      .map((logo) => ({
        src: logo.url,
        width: logo.aspectRatio * 26,
        height: 26,
      })),
    ...page.gameProductsTimeline.products.map((product) => ({
      src: product.imageUrl,
      width: 242,
      height: 211,
    })),
    ...page.staffAvatars.map((avatar) => ({
      src: avatar.imageUrl,
      width: 120,
      height: 120,
    })),
  ];

  await Promise.all(allProps.map(async (props) => await buildViewModel(props)));

  return viewModels;
}
