import React from 'react';
import ArchonIndexPageViewModel from '../../../resources/js/typings/viewModels/archonIndexPageViewModel';
import ArchonLayout, {
  Container,
} from '../../layouts/ArchonLayout/ArchonLayout';
import { ArchonHeaderViewModel } from '../ArchonHeader/ArchonHeader';
import { ArchonFooterViewModel } from '../ArchonFooter/ArchonFooter';
import ArchonTitle from '../ArchonTitle/ArchonTitle';
import GameProductsReel, {
  GameProductsReelGameViewModel,
} from '../GameProductsReel/GameProductsReel';
import GameProductsTimeline from '../GameProductsTimeline/GameProductsTimeline';
import VerticalContent from '../../../resources/js/components/VerticalContent/VerticalContent';
import Heading from '../../../resources/js/components/Heading/Heading';
import Body from '../../../resources/js/components/Body/Body';
import styles from './ArchonIndexPage.module.scss';
import Link from 'next/link';
import { ViewModels, ViewModelsProvider } from '../../contexts/useViewModels';
import LazyLoad from 'react-lazyload';
import convertImageUrlToOptimizedImageUrl from '../../helpers/convertImageUrlToOptimizedImageUrl';
import Head from 'next/head';
import Seo from '../Seo/Seo';
import StaffGrid from '../StaffGrid/StaffGrid';

export type ArchonIndexPageProps = {
  header: ArchonHeaderViewModel;
  footer: ArchonFooterViewModel;
  archonIndexPage: ArchonIndexPageViewModel;
  viewModels: ViewModels;
};

export default function ArchonIndexPage({
  header,
  footer,
  archonIndexPage,
  viewModels,
}: ArchonIndexPageProps): JSX.Element {
  const {
    seo,
    breadcrumbs,
    heading,
    gameProductsReel,
    gameProductsTimeline,
    staffAvatars,
  } = archonIndexPage;

  const initialBackgroundImage = convertImageUrlToOptimizedImageUrl(
    gameProductsReel.games[1].boxArtImageUrl,
    backgroundImageWidth,
    backgroundImageQuality
  );

  return (
    <ViewModelsProvider value={viewModels}>
      <ArchonLayout header={header} breadcrumbs={breadcrumbs} footer={footer}>
        <Head>
          {gameProductsReel.games.map((game) => (
            <link
              key={game.boxArtImageUrl}
              rel={'preload'}
              href={convertImageUrlToOptimizedImageUrl(
                game.boxArtImageUrl,
                backgroundImageWidth,
                backgroundImageQuality
              )}
              as={'image'}
              media={'(min-width: 1024px)'}
            />
          ))}
        </Head>
        <Seo {...seo} />
        <VerticalContent gap={'xlarge'} style={{ overflowX: 'hidden' }}>
          <Container style={{ paddingBottom: 70 }}>
            <div className={styles.backgroundImage}>
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                id={'background-image-a'}
                src={initialBackgroundImage}
                alt={''}
                width={1600}
                height={2124}
                loading={'lazy'}
                style={{ opacity: 1 }}
              />
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                id={'background-image-b'}
                src={initialBackgroundImage}
                alt={''}
                width={1600}
                height={2124}
                loading={'lazy'}
                style={{ opacity: 0 }}
              />
              <div className={styles.backgroundImageOverlay} />
            </div>
            <Container>
              <ArchonTitle heading={heading} />
              <VerticalContent>
                <div className={styles.textContent}>
                  <Body>
                    <Heading level={2} styleLevel={1}>
                      {trans('archon.level_up_your_gameplay')}
                    </Heading>
                    <p>{trans('archon.level_up_your_gameplay_desc')}</p>
                  </Body>
                </div>
                <GameProductsReel
                  {...gameProductsReel}
                  onBoxArtMouseEnter={updateBackgroundImage}
                />
              </VerticalContent>
            </Container>
          </Container>
          <VerticalContent gap={'xlarge'} style={{ marginTop: 0 }}>
            <LazyLoad height={1200}>
              <div className={styles.accentContainer}>
                <Container>
                  <VerticalContent gap={'xlarge'}>
                    <div className={styles.textContent}>
                      <Body>
                        <Heading level={2} styleLevel={1}>
                          {trans('archon.what_weve_built_so_far')}
                        </Heading>
                        <p>{trans('archon.what_weve_built_so_far_desc')}</p>
                      </Body>
                    </div>
                    <GameProductsTimeline {...gameProductsTimeline} />
                  </VerticalContent>
                </Container>
              </div>
            </LazyLoad>
            <Container>
              <VerticalContent gap={'xlarge'}>
                <div className={styles.textContent}>
                  <Body>
                    <Heading level={2} styleLevel={1}>
                      {trans('archon.meet_the_team')}
                    </Heading>
                    <p>{trans('archon.meet_the_team_desc')}</p>
                    <Link href={'/about'} className={styles.learnMoreLink}>
                      {trans('archon.learn_more_about_the_archon_team')}
                    </Link>
                  </Body>
                </div>
                <StaffGrid staff={staffAvatars} />
                <Body className={styles.meetTheTeamContent}>
                  <VerticalContent gap={'xlarge'}>
                    <div>
                      <Heading level={3}>
                        {trans('archon.passionate_team')}
                      </Heading>
                      <p>{trans('archon.passionate_team_desc')}</p>
                    </div>
                    <div className={styles.meetTheTeamContentRight}>
                      <Heading level={3}>
                        {trans('archon.product_focused')}
                      </Heading>
                      <p>{trans('archon.product_focused_desc')}</p>
                    </div>
                    <div>
                      <Heading level={3}>
                        {trans('archon.fully_remote')}
                      </Heading>
                      <p>{trans('archon.fully_remote_desc')}</p>
                    </div>
                  </VerticalContent>
                </Body>
              </VerticalContent>
            </Container>
          </VerticalContent>
        </VerticalContent>
      </ArchonLayout>
    </ViewModelsProvider>
  );
}

const backgroundImageWidth = 256;
const backgroundImageQuality = 75;

const updateBackgroundImage = (game: GameProductsReelGameViewModel) => {
  const backgroundImageA = global.document?.getElementById(
    'background-image-a'
  ) as HTMLImageElement;
  const backgroundImageB = global.document?.getElementById(
    'background-image-b'
  ) as HTMLImageElement;

  let currentBackgroundImage = backgroundImageA;
  let nextBackgroundImage = backgroundImageB;
  if (backgroundImageB.style.opacity === '1') {
    currentBackgroundImage = backgroundImageB;
    nextBackgroundImage = backgroundImageA;
  }

  if (currentBackgroundImage && nextBackgroundImage) {
    const src = convertImageUrlToOptimizedImageUrl(
      game.boxArtImageUrl,
      backgroundImageWidth,
      backgroundImageQuality
    );
    if (currentBackgroundImage.src.endsWith(src)) {
      return;
    }

    nextBackgroundImage.src = src;
    nextBackgroundImage.style.opacity = '1';
    currentBackgroundImage.style.opacity = '0';
  }
};
