import React from 'react';
import styles from './GameBoxArt.module.scss';
import OptimizedImage from '../OptimizedImage/OptimizedImage';
import Link from 'next/link';

export type GameBoxArtProps = {
  name: string;
  url: string;
  src: string;
  onMouseEnter?: () => void;
};

export default function GameBoxArt({
  name,
  url,
  src,
  onMouseEnter,
}: GameBoxArtProps): JSX.Element {
  return (
    <Link href={url} className={styles.link} onMouseEnter={onMouseEnter}>
      <div className={styles.container}>
        <OptimizedImage
          src={src}
          alt={name}
          width={148}
          height={198}
          isZoomable={false}
          includeMargin={false}
          quality={25}
          priority={true}
        />
      </div>
    </Link>
  );
}
