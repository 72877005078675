import React from 'react';
import { StaffAvatarViewModel } from '../StaffAvatar/StaffAvatar';
import styles from './StaffGrid.module.scss';
import StaffBox from '../StaffBox/StaffBox';

export type StaffGridProps = {
  staff: StaffAvatarViewModel[];
};

export default function StaffGrid({ staff }: StaffGridProps): JSX.Element {
  return (
    <div className={styles.grid}>
      {staff.map((staffMember, index) => (
        <StaffBox key={index} {...staffMember} />
      ))}
    </div>
  );
}
